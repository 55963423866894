const sharedResources = require('../../shared/resources');

/* eslint-disable max-len */
const enCopy = {
  error: {
    forgotPassword: {
      //     too_many_requests: 'You have reached the limit on password change attempts. Please wait before trying again.',
      'lock.fallback': 'Something went wrong. Try again.',
      //     enterprise_email: 'Your email's domain is part of an Enterprise identity provider. To reset your password, please see your security administrator.',
    },
    login: {
      //     blocked_user: 'The user is blocked.',
      invalid_user_password: 'Incorrect email or password. Try again.',
      //     invalid_captcha: 'Solve the challenge question to verify you are not a robot.',
      //     invalid_recaptcha: 'Select the checkbox to verify you are not a robot.',
      'lock.fallback': 'Something went wrong. Try again.',
      //     'lock.invalid_code': 'Wrong code.',
      'lock.invalid_email_password': 'Your email or password is incorrect. Please check your details and try again.',
      //     'lock.invalid_username_password': 'Wrong username or password.',
      //     'lock.network': 'We could not reach the server. Please check your connection and try again.',
      //     'lock.popup_closed': 'Popup window closed. Try again.',
      'lock.unauthorized': 'Account locked. Reset password to continue.',
      //     'lock.mfa_registration_required': 'Multifactor authentication is required but your device is not enrolled. Please enroll it before moving on.',
      //     'lock.mfa_invalid_code': 'Wrong code. Please try again.',
      //     password_change_required: 'You need to update your password because this is the first time you are logging in, or because your password has expired.', // TODO: verify error code
      //     password_leaked: 'We have detected a potential security issue with this account. To protect your account, we have blocked this login. An email was sent with instruction on how to unblock your account.',
      too_many_attempts: 'Account locked. Reset password to continue.',
      //     too_many_requests: "We're sorry. There are too many requests right now. Please reload the page and try again. If this persists, please try again later.",
      //     session_missing: "Couldn't complete your authentication request. Please try again after closing all open dialogs",
      //     'hrd.not_matching_email': 'Please use your corporate email to login.'
    },
    //   passwordless: {
    //     'bad.email': 'The email is invalid',
    //     'bad.phone_number': 'The phone number is invalid',
    //     'lock.fallback': "We're sorry, something went wrong"
    //   },
    signUp: {
      //     invalid_password: 'Password is invalid.',
      'lock.fallback': 'Something went wrong. Try again.',
      //     password_dictionary_error: 'Password is too common.',
      //     password_no_user_info_error: 'Password is based on user information.',
      password_strength_error: 'Your password does not meet the requirements.',
      user_exists: 'An account with this email already exists.',
      invalid_phone_number: 'The phone number must be between 10 and 20 characters',
      //     username_exists: 'The username already exists.',
      //     social_signup_needs_terms_acception: 'Please agree to the Terms of Service below to continue.'
    },
  },
  blankEmailErrorHint: 'Enter email address.',
  // codeInputPlaceholder: 'your code',
  // databaseEnterpriseLoginInstructions: '',
  // databaseEnterpriseAlternativeLoginInstructions: 'or',
  // databaseSignUpInstructions: '',
  // databaseAlternativeSignUpInstructions: 'or',
  // emailInputPlaceholder: 'yours@example.com',
  // captchaCodeInputPlaceholder: 'Enter the code shown above',
  // captchaMathInputPlaceholder: 'Solve the formula shown above',
  // enterpriseLoginIntructions: 'Login with your corporate credentials.',
  // enterpriseActiveLoginInstructions: 'Please enter your corporate credentials at %s.',
  // failedLabel: 'Failed!',
  forgotPasswordTitle: 'Reset your password',
  forgotPasswordAction: 'Forgot your password?',
  forgotPasswordInstructions: 'Enter your email address and we’ll send you a link to reset your password.',
  forgotPasswordSubmitLabel: 'Send Reset Link',
  forgotPasswordLinkExpired: 'Expired link: Request new link to reset your password.',
  // invalidErrorHint: '',
  invalidPasswordErrorHint: 'Your password does not meet the requirements.',
  invalidEmailErrorHint: 'Enter valid email.',
  // invalidUsernameErrorHint: 'Username is invalid',
  // lastLoginInstructions: 'Last time you logged in with',
  // loginAtLabel: 'Log in at %s',
  loginLabel: 'Sign In',
  loginSubmitLabel: 'Sign In',
  // loginWithLabel: 'Sign in with %s',
  // notYourAccountAction: 'Not your account?',
  // passwordInputPlaceholder: 'your password',
  title: 'Sign in to your account',
  // passwordlessEmailAlternativeInstructions: 'Otherwise, enter your email to sign in<br/>or create an account',
  // passwordlessEmailCodeInstructions: 'An email with the code has been sent to %s.',
  // passwordlessEmailInstructions: 'Enter your email to sign in<br/>or create an account',
  // passwordlessSMSAlternativeInstructions: 'Otherwise, enter your phone to sign in<br/>or create an account',
  // passwordlessSMSCodeInstructions: 'An SMS with the code has been sent to %s.',
  // passwordlessSMSInstructions: 'Enter your phone to sign in<br/>or create an account',
  // phoneNumberInputPlaceholder: 'your phone number',
  // resendCodeAction: 'Did not get the code?',
  // resendLabel: 'Resend',
  // resendingLabel: 'Resending...',
  // retryLabel: 'Retry',
  // sentLabel: 'Sent!',
  // showPassword: 'Show password',
  signUpTitle: 'Create your account',
  signUpLabel: 'Create an account',
  signUpSubmitLabel: 'Create Account',
  // signUpTerms: 'By signing up, you agree to our terms of service and privacy policy.',
  // signUpWithLabel: 'Sign up with %s',
  // socialLoginInstructions: '',
  // socialSignUpInstructions: '',
  // ssoEnabled: 'Single Sign-On enabled',
  // submitLabel: 'Submit',
  // unrecoverableError: 'Something went wrong.<br />Please contact technical support.',
  // usernameFormatErrorHint: 'Use %d-%d letters, numbers and the following characters: "_", ".", "+", "-"',
  // usernameInputPlaceholder: 'your username',
  // usernameOrEmailInputPlaceholder: 'username/email',
  // welcome: 'Welcome %s!',
  // windowsAuthInstructions: 'You are connected from your corporate network&hellip;',
  // windowsAuthLabel: 'Windows Authentication',
  // mfaInputPlaceholder: 'Code',
  // mfaLoginTitle: '2-Step Verification',
  // mfaLoginInstructions: 'Please enter the verification code generated by your mobile application.',
  // mfaSubmitLabel: 'Log In',
  // mfaCodeErrorHint: 'Use %d numbers'
  privacyPolicy: 'Privacy Policy',
  privacyPolicyHref: 'en-ca/legal/privacy-policy/',
  termsOfUse: 'Terms of Use',
  termsOfUseHref: 'en-ca/legal/terms-of-use/ ',
  copyright: 'All rights reserved.',
  passwordLabel: 'Password',
  emailLabel: 'Email address',
  goBack: 'go back',
  firstName: 'First name',
  lastName: 'Last name',
  daytimePhone: 'Phone number',
  emailOptIn: 'Join our email list to get exclusive offers, the best in books, and more. You may unsubscribe at any time.',
  requiredField: 'Enter information.',
  enterFirstname: 'Enter first name.',
  enterLastname: 'Enter last name.',
  enterPhoneNumber: 'Enter phone number.',
  blankPasswordErrorHint: 'Enter password.',
  createBlankPasswordErrorHint: 'Create password.',
  invalidEmailGlobalError: 'Enter valid email.',
  verifyEmailLinkExpired: 'Expired link: Sign in to request new link to verify your email.',
  ...sharedResources.enCopy,
};

const frCopy = {
  error: {
    forgotPassword: {
      // too_many_requests: 'Vous avez atteint la limite de tentatives de changement de mot de passe. Veuillez patienter avant de recommencer.',
      'lock.fallback': 'Une erreur s\'est produite. Veuillez réessayer.',
      // enterprise_email: "Le domaine de votre messagerie fait partie d'un fournisseur d'identité d'entreprise. Pour réinitialiser votre mot de passe, veuillez contacter votre administrateur de sécurité."
    },
    login: {
      //     blocked_user: 'L’utilisateur est bloqué.',
      invalid_user_password: 'L\'adresse de courriel ou le mot de passe est invalide. Veuillez réessayer.',
      //     invalid_captcha: "Résolvez la question du défi pour vérifier que vous n'êtes pas un robot.",
      //     invalid_recaptcha: "Cochez la case pour vérifier que vous n'êtes pas un robot."
      'lock.fallback': 'Une erreur s\'est produite. Veuillez réessayer.',
      //     'lock.invalid_code': 'Mauvais code.',
      'lock.invalid_email_password': 'Votre adresse de courriel ou votre mot de passe ne correspondent pas. Veuillez vérifier vos identifiants de connexion et réessayer.',
      //     'lock.invalid_username_password': 'Mauvais nom d’utilisateur ou mot de passe.',
      //     'lock.network': 'Nous ne pouvons pas joindre le serveur. Vérifiez votre connexion et réessayez.',
      //     'lock.popup_closed': 'La fenêtre popup a été fermée. Veuillez réessayer.',
      'lock.unauthorized': 'Le compte a été verrouillé. Veuillez réinitialiser le mot de passe pour continuer.',
      //     'lock.mfa_registration_required': "l'authentification multifactorielle est nécessaire, mais votre appareil n'est pas inscrit. Veuillez vous inscrire avant de passer.",
      //     'lock.mfa_invalid_code': 'Mauvais code. Veuillez réessayer.',
      //     password_change_required: 'Vous devez mettre à jour votre mot de passe, soit parce qu’il s’agit de votre première connexion, soit parce que ce dernier a expiré.',
      //     password_leaked: 'Cette connexion a été bloquée parce que votre mot de passe a été utilisé sur un autre site web. Nous vous avons envoyé un courriel avec des instructions pour la débloquer.',
      too_many_attempts: 'Le compte a été verrouillé. Veuillez réinitialiser le mot de passe pour continuer.',
      //     too_many_requests: 'Nous sommes désolés. Il y a trop de demandes en ce moment. Veuillez recharger la page et réessayer. Si cela persiste, veuillez réessayer ultérieurement.',
      //     session_missing: "Impossible de terminer votre demande d'authentification. Veuillez essayer de nouveau après la fermeture de tous les dialogues ouverts",
      //     'hrd.not_matching_email': "Veuillez utiliser votre messagerie d'entreprise pour vous connecter.",
    },
    //   passwordless: {
    //     'bad.email': 'L’adresse de messagerie n’est pas valide',
    //     'bad.phone_number': 'Le numéro de téléphone n’est pas valide',
    //     'lock.fallback': 'Nous sommes désolés, un problème est survenu'
    //   },
    signUp: {
      //     invalid_password: 'Le mot de passe n’est pas valide.',
      'lock.fallback': 'Une erreur s\'est produite. Veuillez réessayer.',
      //     password_dictionary_error: 'Le mot de passe est trop commun.',
      //     password_no_user_info_error: 'Le mot de passe est basé sur des informations utilisateur.',
      password_strength_error: 'Le mot de passe ne répond pas aux critères.',
      user_exists: 'Un compte lié à cette adresse de courriel existe déjà.',
      invalid_phone_number: 'Le numéro de téléphone doit comporter de 10 à 20 caractères.',
      //     username_exists: 'Ce nom d’utilisateur existe déjà.',
      //     social_signup_needs_terms_acception: "Veuillez accepter les conditions d'utilisation ci-dessous pour continuer."
    },
  },
  blankEmailErrorHint: 'Entrez une adresse de courriel.',
  // codeInputPlaceholder: 'votre code',
  // databaseEnterpriseLoginInstructions: '',
  // databaseEnterpriseAlternativeLoginInstructions: 'ou',
  // databaseSignUpInstructions: '',
  // databaseAlternativeSignUpInstructions: 'ou',
  // emailInputPlaceholder: 'votreadresse@exemple.com',
  // captchaCodeInputPlaceholder: 'Entrez le code ci-dessus',
  // captchaMathInputPlaceholder: 'Résolvez la formule ci-dessus'
  // enterpriseLoginIntructions: 'Connectez-vous avec vos identifiants d’entreprise.',
  // enterpriseActiveLoginInstructions: 'Veuillez entrer les identifiants de connexion de votre entreprise %s.',
  // failedLabel: 'A échoué !',
  forgotPasswordTitle: 'Réinitialisez votre mot de passe',
  forgotPasswordAction: 'Vous avez oublié votre mot de passe?',
  forgotPasswordInstructions: 'Entrez votre adresse de courriel et nous vous enverrons un lien vous permettant de réinitialiser votre mot de passe.',
  forgotPasswordSubmitLabel: 'Envoyer le lien',
  forgotPasswordLinkExpired: 'Lien expiré. Veuillez faire une nouvelle demande de réinitialisation.',
  // invalidErrorHint: '',
  invalidPasswordErrorHint: 'Le mot de passe ne répond pas aux critères.',
  invalidEmailErrorHint: 'Entrez une adresse de courriel valide.',
  // invalidUsernameErrorHint: 'Invalide',
  // lastLoginInstructions: 'Dernière connexion avec',
  // loginAtLabel: 'Connexion à %s',
  loginLabel: 'Ouvrez une session',
  loginSubmitLabel: 'Ouvrir une session',
  // loginWithLabel: 'Se connecter avec %s',
  // notYourAccountAction: 'Ceci n’est pas votre compte ?',
  // passwordInputPlaceholder: 'Votre mot de passe',
  title: 'Ouvrez une session dans votre compte',
  // passwordlessEmailAlternativeInstructions: 'Sinon entrez votre adresse de messagerie pour vous connecter<br/>ou créez un compte',
  // passwordlessEmailCodeInstructions: 'Un courriel avec le code a été envoyé à %s.',
  // passwordlessEmailInstructions: 'Entrez votre adresse de messagerie pour vous connecter<br/>ou créez un compte',
  // passwordlessSMSAlternativeInstructions: 'Sinon saisissez votre numéro de téléphone pour vous connecter<br/>ou créez un compte',
  // passwordlessSMSCodeInstructions: 'Un SMS avec le code a été envoyé à %s.',
  // passwordlessSMSInstructions: 'Saisissez votre numéro de téléphone pour vous connecter<br/>ou créez un compte',
  // phoneNumberInputPlaceholder: 'votre numéro de téléphone',
  // resendCodeAction: 'Vous n’avez pas reçu le code ?',
  // resendLabel: 'Envoyer une nouvelle fois',
  // resendingLabel: 'Nouvel envoi en cours…',
  // retryLabel: 'Réessayer',
  // sentLabel: 'Envoyé !',
  // showPassword: 'Montrer le mot de passe',
  signUpTitle: 'Créez votre compte',
  signUpLabel: 'Créez un compte',
  signUpSubmitLabel: 'Créer un compte',
  // signUpTerms: "En vous inscrivant, vous acceptez nos conditions d'utilisation et notre politique de confidentialité.",
  // signUpWithLabel: 'S’inscrire avec %s',
  // socialLoginInstructions: '',
  // socialSignUpInstructions: '',
  // ssoEnabled: 'Authentification unique activée',
  // submitLabel: 'Envoyer',
  // unrecoverableError: 'Un problème est survenu.<br />Veuillez contacter l’assistance technique.',
  // usernameFormatErrorHint: 'Utilisez %d-%d lettres, chiffres et les caractères suivants: "_", ".", "+", "-"',
  // usernameInputPlaceholder: 'votre nom d’utilisateur',
  // usernameOrEmailInputPlaceholder: 'nom d’utilisateur/adresse de messagerie',
  // welcome: 'Bienvenue %s !',
  // windowsAuthInstructions: 'Vous êtes connecté depuis votre réseau d’entreprise...',
  // windowsAuthLabel: 'Authentification Windows',
  // mfaInputPlaceholder: 'Code',
  // mfaLoginTitle: '2-Step Vérification',
  // mfaLoginInstructions: 'Veuillez entrer le code de vérification généré par votre application mobile.',
  // mfaSubmitLabel: "S'identifier",
  // mfaCodeErrorHint: 'Utilisez des numéros %d',
  privacyPolicy: 'Politique de confidentialité',
  privacyPolicyHref: 'fr-ca/politique-juridique/politique-de-confidentialite/',
  termsOfUse: 'Conditions d’utilisation',
  termsOfUseHref: 'fr-ca/politique-juridique/conditions-dutilisation/',
  copyright: 'Tous droits réservés.',
  passwordLabel: 'Mot de passe',
  emailLabel: 'Adresse de courriel',
  goBack: 'retour',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  daytimePhone: 'Numéro de téléphone',
  emailOptIn: 'Inscrivez-vous à notre liste de diffusion pour recevoir des offres exclusives, des recommandations de lecture et bien plus. Vous pouvez vous désinscrire à tout moment.',
  requiredField: 'Entrez les renseignements.',
  enterFirstname: 'Entrez le prénom.',
  enterLastname: 'Entrez le nom de famille.',
  enterPhoneNumber: 'Entrez le numéro de téléphone',
  blankPasswordErrorHint: 'Entrez le mot de passe.',
  createBlankPasswordErrorHint: 'Créez un mot de passe.',
  invalidEmailGlobalError: 'Entrez une adresse de courriel.',
  verifyEmailLinkExpired: 'Lien expiré. Ouvrez une session pour demander qu\'on vous envoie un nouveau courriel de validation.',
  ...sharedResources.frCopy,
};

module.exports = {
  enCopy,
  frCopy,
};
