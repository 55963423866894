/* eslint-disable max-len */
const enCopy = {
    // success: {
    //   // success messages show above the form or in a confirmation pane
    //   logIn: 'Thanks for logging in.',
    //   forgotPassword: "We've just sent you an email to reset your password.",
    //   magicLink: 'We sent you a link to log in<br />to %s.',
    //   signUp: 'Thanks for signing up.'
    // },
    // blankErrorHint: '',
    // blankPasswordErrorHint: 'Create password.',
    // blankUsernameErrorHint: "Username can't be blank",
    // blankCaptchaErrorHint: "Can't be blank",

    passwordStrength: {
      containsAtLeast: 'Include %d of these %d:',
      //   identicalChars: 'No more than %d identical characters in a row (e.g., "%s" not allowed)',
      //   nonEmpty: 'Non-empty password required',
      numbers: 'A number',
      lengthAtLeast: 'A minimum of %d characters',
      lowerCase: 'A lowercase letter',
      //   shouldContain: 'Should contain:',
      specialCharacters: 'A symbol',
      upperCase: 'An uppercase letter',
    },
    pwdStrengthTitle: 'Password requirements',
};
  
const frCopy = {
    // success: {
    //   logIn: 'Merci de vous être connecté.',
    //   forgotPassword: 'Nous venons de vous envoyer un courriel pour réinitialiser votre mot de passe.',
    //   magicLink: 'Nous vous avons envoyé un lien pour vous connecter<br />à %s.',
    //   signUp: 'Merci de vous être inscrit.'
    // },
    // blankErrorHint: '',
    // blankPasswordErrorHint: 'Créez un mot de passe.',
    // blankUsernameErrorHint: 'Ne peut être vide',
    // blankCaptchaErrorHint: 'Ne peut être vide',

    passwordStrength: {
        containsAtLeast: 'Au moins %d de ces %d éléments:',
        //   identicalChars: 'Pas plus de %d caractères identiques dans une ligne (par ex., « %s » n’est pas autorisé)',
        //   nonEmpty: 'Mot de passe non vide requis',
        numbers: 'Un chiffre',
        lengthAtLeast: 'Au moins %d caractères',
        lowerCase: 'Une lettre minuscule',
        //   shouldContain: 'Doit contenir :',
        specialCharacters: 'Un symbole',
        upperCase: 'Une lettre majuscule',
      },
      pwdStrengthTitle: 'Votre mot de passe doit comprendre',
};

module.exports = {
  enCopy,
  frCopy,
};
