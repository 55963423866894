const { enCopy, frCopy } = require('./resources');

let forgotPasswordFlag = true;
let createPasswordFlag = false;
let replaceErrorMessage = '';
const FORM_MODE = {
  sign_in: 0,
  sign_up: 1,
  forgot_password: 2,
};

let initFormNode = false;
let initVisuallyHiddenMessage = false;

// handle languages
const params = (new URL(document.location)).searchParams;
const lang = params.get('lang');
const isFrench = lang === 'fr' || window.navigator.language.startsWith('fr');
document.title = isFrench ? frCopy.title : enCopy.title;

// initialize auth0 widget
const terms = isFrench ? frCopy : enCopy;
window.loginConfig.language = isFrench ? 'fr' : 'en';
window.loginConfig.languageDictionary = {
  ...window.loginConfig.languageDictionary,
  ...terms,
};

window.loginConfig.avatar = null;
window.loginConfig.allowShowPassword = true;
window.loginConfig.additionalSignUpFields = [
  {
    name: 'signup_given_name',
    placeholder: terms.firstName,
    validator(firstName) {
      return {
        valid: !!firstName,
        hint: terms.enterFirstname,
      };
    },
  },
  {
    name: 'signup_family_name',
    placeholder: terms.lastName,
    validator(lastName) {
      return {
        valid: !!lastName,
        hint: terms.enterLastname,
      };
    },
  },
  {
    name: 'phone_daytime',
    placeholder: terms.daytimePhone,
    validator(phoneNumber) {
      return {
        valid: !!phoneNumber,
        hint: terms.enterPhoneNumber,
      };
    },
  },
  {
    type: 'checkbox',
    name: 'signup_email_opt_in',
    placeholder: terms.emailOptIn,
    validator() {
      return true;
    },
  },
];

const isChangePasswordLinkExpired = params.get('isChangePasswordLinkExpired') === 'true';

if (isChangePasswordLinkExpired) {
  window.loginConfig.initialScreen = 'forgotPassword';
}

const lock = new window.Auth0Lock(window.config.clientID, window.config.auth0Domain, window.loginConfig);

const isVerifyEmailLinkExpired = params.get('isVerifyEmailLinkExpired') === 'true';

if (isChangePasswordLinkExpired) {
  const changePasswordLinkExpiredMessage = {
    flashMessage: {
      type: 'error',
      text: isFrench ? frCopy.forgotPasswordLinkExpired : enCopy.forgotPasswordLinkExpired,
    },
  };
  lock.show(changePasswordLinkExpiredMessage);
} else if (isVerifyEmailLinkExpired) {
  const verifyEmailLinkExpiredMessage = {
    flashMessage: {
      type: 'error',
      text: isFrench ? frCopy.verifyEmailLinkExpired : enCopy.verifyEmailLinkExpired,
    },
  };
  lock.show(verifyEmailLinkExpiredMessage);
} else {
  lock.show();
}

lock.on('signup error', (error) => {
  const globalMessageError = document.querySelector('#auth0-lock-container-1 .auth0-global-message-error');
  if (error && error.code) {
    if (error.code === 'user_exists' || error.code.includes('error in email')) {
      const emailField = document.getElementById('1-email');
      emailField.setAttribute('aria-invalid', 'true');
      if (error.code.includes('error in email')) {
        globalMessageError.querySelectorAll('span')[1].innerHTML = terms.invalidEmailGlobalError;
      }
    }
  }
  if (error && error.description) {
    if (terms.error.signUp[error.description]) {
      if (globalMessageError && globalMessageError.querySelectorAll('span')[1]) {
        globalMessageError.querySelectorAll('span')[1].innerHTML = terms.error.signUp[error.description];
      } else {
        replaceErrorMessage = terms.error.signUp[error.description];
      }
    }
  }
});

lock.on('authorization_error', (error) => {
  if (error.error === 'invalid_user_password') {
    const emailField = document.getElementById('1-email');
    emailField.parentNode.classList.add('invalid-user-password');
    emailField.setAttribute('aria-invalid', 'true');
    const passwordField = document.getElementById('1-password');
    passwordField.parentNode.classList.add('invalid-user-password');
    passwordField.setAttribute('aria-invalid', 'true');
  }

  window.indigoAnalyticsDataLayer.push({
    event: 'loginError',
    error: {
      error: error.description,
    },
  });
});

lock.on('signin submit', () => {
  // remove exisitng error on hidden alert
  const errorMessageVisuallyHidden = document.getElementById('errorMessageVisuallyHidden');
  const errorMessage = document.createTextNode('');
  errorMessageVisuallyHidden.replaceChildren(errorMessage);

  window.indigoAnalyticsDataLayer.push({
    event: 'clickLogin',
  });
});

lock.on('signup ready', () => {
  const pwInput = document.getElementById('1-password');
  const pwdStrengthVisuallyHidden = document.createElement('div');
  pwdStrengthVisuallyHidden.id = 'pwdStrengthVisuallyHidden';
  pwdStrengthVisuallyHidden.setAttribute('role', 'alert');
  pwdStrengthVisuallyHidden.classList.add('visually-hidden');
  pwInput.parentNode.appendChild(pwdStrengthVisuallyHidden);
});

lock.on('signup submit', () => {
  // remove exisitng error on hidden alert
  const errorMessageVisuallyHidden = document.getElementById('errorMessageVisuallyHidden');
  const errorMessage = document.createTextNode('');
  errorMessageVisuallyHidden.replaceChildren(errorMessage);
});

lock.on('signup success', (result) => {
  window.indigoAnalyticsDataLayer.push({
    event: 'createAccount',
    user: {
      email: result.email,
      membershipID: result.userId,
      emailOptIn: result.userMetadata.signupEmailOptIn,
      status: 'registered',
    },
  });
});

const pwrequirements = document.querySelector('form');
if (pwrequirements) pwrequirements.style.setProperty('--pw-requirements', `'${terms.pwdStrengthTitle}'`);

function getCommonSvgElems(width, height, className = '') {
  const namespace = 'http://www.w3.org/2000/svg';
  const svgElem = document.createElementNS(namespace, 'svg');
  const svgPath = document.createElementNS(namespace, 'path');

  svgElem.setAttribute('aria-hidden', 'true');
  svgElem.setAttribute('role', 'img');
  svgElem.setAttribute('focusable', 'false');
  svgElem.setAttribute('width', `${width}`);
  svgElem.setAttribute('height', `${height}`);
  svgElem.setAttribute('viewbox', `0 0 ${width} ${height}`);
  if (className) {
    svgElem.classList.add(className);
  }

  return [svgElem, svgPath];
}

function getBackSvg() {
  const [svgElem, svgPath] = getCommonSvgElems(24, 24, 'back-arrow-svg');

  svgElem.setAttribute('version', '1.0');
  // eslint-disable-next-line max-len
  svgPath.setAttributeNS(null, 'd', 'M20.9502 7.99995L13.1623 0.212036L11.8364 1.53786L17.361 7.06247H0.5V8.93747H17.361L11.8364 14.462L13.1623 15.7879L20.9502 7.99995Z');

  svgElem.appendChild(svgPath);

  return svgElem;
}

function reorderFormFields() {
  const firstNameNode = document.querySelector('.auth0-lock-input-signup_given_name');
  const lastNameNode = document.querySelector('.auth0-lock-input-signup_family_name');
  const phoneNode = document.querySelector('.auth0-lock-input-phone_daytime');
  const emailNode = document.querySelector('.auth0-lock-input-email');

  if (emailNode) {
    emailNode.parentNode.insertBefore(firstNameNode, emailNode);
    emailNode.parentNode.insertBefore(lastNameNode, emailNode);
    emailNode.parentNode.insertBefore(phoneNode, emailNode);
  }
}

function augmentMarkup() {
  // add a lang="xx" attribute to the top-level HTML tag
  const htmlTag = document.querySelector('html');
  htmlTag.setAttribute('lang', window.loginConfig.language);

  // relocate the navigation bar when in sign-in mode only...
  const titleElem = document.querySelector('.auth0-lock-name') || '';
  const origTabs = document.querySelector('.auth0-lock-form > div > .auth0-lock-tabs-container');
  const newTabs = document.querySelector('.auth0-lock-cred-pane-internal-wrapper > .auth0-lock-tabs-container');
  const newTabsWrapper = document.querySelector('.auth0-lock-cred-pane-internal-wrapper');

  // [a11y] remove redundant title attribute and add attributes to identify the title as a heading...
  titleElem.setAttribute('id', 'auth0-lock-name');
  titleElem.removeAttribute('title');

  const titleCopy = document.getElementById('auth0-lock-name');
  const val = document.getElementById('auth0-lock-name').innerHTML;
  titleCopy.innerHTML = `<h1>${val}</h1>`;

  const slideOne = document.querySelector('#slideOne');
  slideOne.classList.add('visually-hidden');
  const showPwlabel = slideOne.nextElementSibling;
  showPwlabel.setAttribute('aria-label', 'Show Password');
  showPwlabel.removeAttribute('title');

  // [a11y] remove the placeholder attributes since they add extra noise for screen reader...
  document.querySelectorAll('input[type="email"], input[type="password"]')
    .forEach((elem) => elem.removeAttribute('placeholder'));

  if (titleElem.textContent === terms.title) {
    if (origTabs) {
      const origTabsParent = origTabs.parentNode;
      if (newTabs) {
        newTabsWrapper.removeChild(newTabs);
      }
      origTabsParent.removeChild(origTabs);
      newTabsWrapper.appendChild(origTabs);
    }
  } else if (titleElem.textContent === terms.signUpTitle) {
    if (newTabs) {
      newTabsWrapper.removeChild(newTabs);
    }
  }

  // create the back arrow SVG for when in log in/sign up mode; the back arrow for forgot password
  // is created automatically by lock
  // (note: when the two arrows are present at the same time, this one is styled out)
  if (!document.querySelector('.back-button-alt')) {
    const arrowBackBtn = document.createElement('button');
    const a11yTextSpan = document.createElement('span');
    a11yTextSpan.classList.add('sr-only');
    a11yTextSpan.textContent = terms.goBack;
    arrowBackBtn.appendChild(a11yTextSpan);

    arrowBackBtn.type = 'button';
    arrowBackBtn.className = 'back-button-alt';
    arrowBackBtn.appendChild(getBackSvg());
    document.querySelector('.auth0-lock-header').prepend(arrowBackBtn);
    document.querySelector('.back-button-alt').addEventListener('click', (e) => {
      e.preventDefault();
      const resetPasswordBackCTA = document.querySelector('.auth0-lock-back-button');
      const formNode = document.querySelector('form.auth0-lock-widget');
      const formMode = formNode.getAttribute('data-mode');
      const signUpBackCTA = document.querySelector('.auth0-lock-tabs a');
      if (resetPasswordBackCTA) {
        resetPasswordBackCTA.click();
      } else if (Number(formMode) === FORM_MODE.sign_up && signUpBackCTA) {
        signUpBackCTA.click();
      } else {
        // eslint-disable-next-line no-restricted-globals
        history.back();
      }
    }, false);
  }

  // add legal copy footer
  if (!document.querySelector('.legal-container')) {
    const legalContainer = document.createElement('div');
    legalContainer.classList.add('legal-container');
    legalContainer.innerHTML = `<a href="${new URL(terms.privacyPolicyHref, AUTH0_STOREFRONT_BASE_URL)}" 
      target="_blank">${terms.privacyPolicy}</a>
      <a href="${new URL(terms.termsOfUseHref, AUTH0_STOREFRONT_BASE_URL)}" target="_blank">${terms.termsOfUse}</a>
      <div class="copyright">&copy; ${(new Date()).getFullYear()} Indigo. ${terms.copyright}</div>`;
    const widgetContainer = document.querySelector('.auth0-lock-widget-container');
    widgetContainer.appendChild(legalContainer);
  }
}

// observe DOM for changes
const targetNode = document.querySelector('body');
// Options for the observer (which mutations to observe)
const config = {
  attributes: true, childList: true, subtree: true, characterData: true,
};

// Callback function to execute when mutations are observed
function callback(mutationList) {
  const formNode = document.querySelector('form.auth0-lock-widget');
  const emailInputField = document.getElementById('1-submit');
  const pwInputField = document.getElementById('1-password');

  if (formNode && emailInputField && !initFormNode) {
    initFormNode = true;
    // const formNode = document.querySelector('form.auth0-lock-widget');
    formNode.setAttribute('data-mode', FORM_MODE.sign_in); // default
    formNode.addEventListener('submit', (e) => {
      e.preventDefault();
      if (emailInputField.getAttribute('aria-label') === 'Send Reset Link'
        || emailInputField.getAttribute('aria-label') === 'Envoyer le lien') {
        window.indigoAnalyticsDataLayer.push({
          event: 'submitForm',
          form: {
            type: 'create new password',
          },
        });

        const emailInputFieldError = document.getElementById('auth0-lock-error-msg-email');
        if (emailInputFieldError) {
          window.indigoAnalyticsDataLayer.push({
            event: 'passwordResetError',
            error: {
              errorID: 'Enter valid email.',
            },
          });
        } else {
          window.indigoAnalyticsDataLayer.push({
            event: 'passwordResetSuccess',
            user: {
              email: document.getElementById('1-email').value,
            },
          });
        }
      }
    });
  }

  // create a div with role alert that is visually hidden and present inside the DOM at all times
  const targetNodeBody = document.querySelector('.auth0-lock-content-body-wrapper');
  if (targetNodeBody && !initVisuallyHiddenMessage) {
    initVisuallyHiddenMessage = true;
    const errorMessageVisuallyHidden = document.createElement('div');
    errorMessageVisuallyHidden.id = 'errorMessageVisuallyHidden';
    errorMessageVisuallyHidden.classList.add('visually-hidden');
    errorMessageVisuallyHidden.setAttribute('role', 'alert');
    targetNodeBody.appendChild(errorMessageVisuallyHidden);
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const mutation of mutationList) {
    // console.log(mutation);
    // The same submit button is used for login, create account, and forgot password and the
    // 'aria-label' attribute mutates on initial load and when changing between the views
    // so that mutation is a good canadidate to trigger the markup updates...
    if (mutation.target.id === '1-submit' && mutation.attributeName === 'aria-label') {
      augmentMarkup();
    }

    // adds a data-attribute to help with targeting elements in CSS when in the various
    // modes (sign in/sign up/reset pwd)...
    if (formNode && mutation.type === 'attributes' && mutation.attributeName === 'title') {
      switch (mutation.target.textContent) {
        case terms.signUpTitle:
          formNode.setAttribute('data-mode', FORM_MODE.sign_up);
          forgotPasswordFlag = true;
          createPasswordFlag = true;
          break;
        case terms.forgotPasswordTitle:
          formNode.setAttribute('data-mode', FORM_MODE.forgot_password);
          break;
        default:
          formNode.setAttribute('data-mode', FORM_MODE.sign_in);
          forgotPasswordFlag = true;
          createPasswordFlag = false;
      }
    }

    if (mutation.type === 'attributes'
      && mutation.attributeName === 'title'
      && mutation.target.textContent === terms.signUpTitle) {
      reorderFormFields();
    }

    if (mutation.type === 'attributes'
      && mutation.attributeName === 'title'
      && mutation.target.textContent === terms.forgotPasswordTitle) {
      if (forgotPasswordFlag) {
        // eslint-disable-next-line no-undef
        // indigoAnalyticsDataLayer.push({
        //   event: 'clickLink',
        //   link: {
        //     type: 'link',
        //     name: document.querySelector('.auth0-lock-alternative-link').innerHTML,
        //   },
        // });
      }
      forgotPasswordFlag = false;
    }

    if (mutation.target.textContent === terms.blankPasswordErrorHint) {
      if (createPasswordFlag) {
        const pwCreationErrLbl = document.getElementById('auth0-lock-error-msg-password');
        if (pwCreationErrLbl) {
          pwCreationErrLbl.innerText = terms.createBlankPasswordErrorHint;
        }
      }
    }

    if (mutation.type === 'childList' && mutation.addedNodes.length) {
      const errors = document.querySelectorAll('.auth0-lock-error-invalid-hint');
      for (let i = 0; i < errors.length; i += 1) {
        errors[i].setAttribute('role', 'alert');
      }

      // eslint-disable-next-line no-loop-func
      mutation.addedNodes.forEach((node) => {
        // Auth0 lock callback functions do not account for potential offline errors.
        // A mutation is used to account for those types of errors and log analytics.
        if (node.classList && node.classList.contains('auth0-global-message-error')) {
          if (replaceErrorMessage) {
            document.getElementsByClassName('auth0-global-message-error')[0].innerText = replaceErrorMessage;
            replaceErrorMessage = undefined;
          }
          const errorSpan = document.getElementsByClassName('auth0-global-message-error')[0].innerText;
          if (errorSpan && errorSpan === 'Something went wrong. Try again.') {
            window.indigoAnalyticsDataLayer.push({
              event: 'loginError',
              error: {
                error: errorSpan,
              },
            });
          }

          // set error in visually hidden alert div
          const errorMessageVisuallyHidden = document.getElementById('errorMessageVisuallyHidden');
          const errorMessage = document.createTextNode(errorSpan);
          errorMessageVisuallyHidden.replaceChildren(errorMessage);
        }

        // push description in aria-described element to be announced
        if (node.classList && node.classList.contains('auth0-lock-password-strength')) {
          const pwdStrengthVisuallyHidden = document.getElementById('pwdStrengthVisuallyHidden');
          const errorMessage = document.createTextNode(node.innerText);
          pwdStrengthVisuallyHidden.replaceChildren(errorMessage);
        }
      });
    }

    if (mutation.type === 'childList' && mutation.removedNodes.length) {
      mutation.removedNodes.forEach((node) => {
        // removed description in aria-described element to be announced
        if (node.classList && node.classList.contains('auth0-lock-password-strength')) {
          const pwdStrengthVisuallyHidden = document.getElementById('pwdStrengthVisuallyHidden');
          const errorMessage = document.createTextNode('');
          pwdStrengthVisuallyHidden.replaceChildren(errorMessage);
        }
      });
    }

    if (emailInputField) {
      // const emailInputField = document.getElementById('1-email');
      const parent = emailInputField?.parentElement.closest('div');
      if (emailInputField.getAttribute('aria-invalid') === 'true'
        && emailInputField.value.length !== 0 && parent.classList.contains('auth0-lock-focused')) {
        emailInputField.setAttribute('aria-invalid', 'false');
      }
    }

    if (pwInputField) {
      // const pwInputField = document.getElementById('1-password');
      const pwParent = pwInputField?.parentElement.closest('div');
      if (pwInputField.getAttribute('aria-invalid') === 'true'
        && pwInputField.value.length !== 0 && pwParent.classList.contains('auth0-lock-focused')) {
        pwInputField.setAttribute('aria-invalid', 'false');
      }
    }

    if (mutation.target.type === 'checkbox' && mutation.target?.id === '1-signup_email_opt_in') {
      const checkElem = mutation.target;
      // make the adjacent span element act as the accessibility label for the checkbox
      const labellingSpan = checkElem.parentElement.querySelector('span');
      if (!labellingSpan?.id) {
        labellingSpan.id = 'email-opt-in-label';
        checkElem.removeAttribute('aria-label');
        checkElem.setAttribute('aria-labelledby', labellingSpan.id);
      }
    }
  }
}
// Create an observer instance linked to the callback function
const observer = new MutationObserver(callback);

// Start observing the target node for configured mutations
observer.observe(targetNode, config);
